.book-list-container-parent {
  position: absolute;
  top: 80px;
  left: 220px;
  width: calc(100% - 220px);
  height: calc(100% - 125px);
  overflow: visible;
}
.book-list-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  bottom: 10px;
  left: 220px;
  width: calc(100% - 220px);
}
.book-list-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  user-select: text;
}

.booklist-shelf-list {
  width: 169px;
  height: 31px;
  opacity: 1;
  box-sizing: border-box;
  margin-left: 16px;
  font-size: 15px;
  line-height: 31px;
  border-radius: 5px;
  cursor: pointer;
}
.delete-shelf-icon {
  cursor: pointer;
  margin-left: 20px;
}
.icon-cover {
  font-weight: 500;
}
.booklist-shelf-container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.booklist-manage-container {
  cursor: pointer;
  margin-bottom: 5px;
}
.book-manage-title {
  font-size: 15px;
  font-weight: 500;
  padding: 6px 10px;
  border-radius: 5px;
}
