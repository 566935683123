.note-editor {
  width: calc(100% - 40px);
  height: calc(100% - 140px);
  padding: 20px;
}
.note-original-text {
  border-left: solid 3px #109870;
  padding-left: 10px;
  margin-bottom: 20px;
  margin-top: 5px;
  line-height: 1.25;
  text-align: left;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  font-size: 15px;
}
.editor-box-parent {
  width: 100%;
  height: calc(100% - 15px);
}
.editor-box {
  border: none;
  outline: none;
  resize: none;
  font-size: 15px;
  width: calc(100% - 20px);
  min-height: 120px;
  line-height: 18px;
  overflow-y: scroll;
  background-color: rgba(16, 152, 112, 0.05);
  border-radius: 7px;
  padding: 10px;
}

.editor-box::placeholder {
  font-size: 15px;
  line-height: 15px;
  opacity: 0.6;
  position: relative;
  top: 6px;
  font-weight: 500;
}
.editor-box::-ms-input-placeholder {
  font-size: 15px;
  line-height: 15px;
  opacity: 0.6;
  position: relative;
  top: 6px;
  font-weight: 500;
}
.editor-box::-moz-placeholder {
  font-size: 15px;
  line-height: 15px;
  opacity: 0.6;
  position: relative;
  top: 6px;
  font-weight: 500;
}
.note-button-container {
  position: absolute;
  bottom: 10px;
  right: 10px;
  max-width: 100%;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}
.confirm-button {
  font-size: 15px;
  line-height: 15px;
  opacity: 1;
  cursor: pointer;
}
.cancel-button {
  font-size: 15px;
  line-height: 15px;
  opacity: 1;
  cursor: pointer;
  margin-right: 20px;
}
.note-tags {
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
}
