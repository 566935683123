.menu-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 158px;
  height: 132px;
  padding-left: 1px;
}
.note-option,
.digest-option,
.translation-option,
.speaker-option,
.search-book-option,
.browser-option,
.dict-option,
.wikipedia-option,
.copy-option {
  height: 42px;
  width: 42px;
  font-size: 14px !important;
  line-height: 15px !important;
  opacity: 1;
  text-align: center;
  display: flex;
  box-sizing: border-box;
  cursor: pointer;
  transition: 0.1s;
  /* align-items: center; */
  justify-content: center;
  font-weight: 500;
  margin: 5px;
  margin-bottom: 0px;
  margin-top: 3px;
}
.note-option:hover,
.digest-option:hover,
.translation-option:hover,
.speaker-option:hover,
.search-book-option:hover,
.browser-option:hover,
.dict-option:hover,
.wikipedia-option:hover,
.copy-option:hover {
  border-radius: 5px;
}

.note-icon,
.translation-icon,
.speaker-icon,
.search-book-icon,
.browser-icon,
.dict-icon,
.baidu-icon,
.bing-icon,
.copy-icon,
.wikipedia-icon,
.digest-icon {
  line-height: 45px !important;
  font-size: 27px;
}
.copy-icon {
  color: #ff9900;
}
.digest-icon {
  color: #5e7fff;
}
.note-icon {
  color: #109870;
}
.speaker-icon {
  color: #b266cb;
  font-size: 30px;
  margin-left: 5px;
}
.search-book-icon {
  color: #6867d1;
}
.browser-icon {
  color: #01b7bc;
  font-size: 27px;
}
.dict-icon {
  color: #2084e8;
  font-size: 25px;
}
.wikipedia-icon {
  font-size: 22px;
  opacity: 0.83;
}
.baidu-icon {
  color: rgb(252, 204, 136);
}
.bing-icon {
  color: rgb(252, 204, 136);
}
