.trans-container {
  width: calc(100% - 40px);
  height: calc(100% - 50px);
  padding: 20px;
  margin-top: 50px;
}
.trans-box {
  height: calc(100% - 40px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 7px;
}
.original-text,
.trans-text {
  overflow-y: scroll;
  height: calc(100% - 40px);
  margin: 5px;
  margin-top: 35px;
}
.original-text-box,
.trans-text-box {
  width: 100%;
  height: 100%;
  opacity: 1;
  user-select: text;

  padding: 5px;
  box-sizing: border-box;
  font-size: 15px;
  line-height: 18px;
  /* position: relative; */
}
.trans-lang-selector-container {
  width: calc(100% - 40px);
  height: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  position: absolute;
  top: 67px;
}
.trans-lang-box,
.original-lang-box {
  width: calc(50%);
  height: 100%;
  padding-left: 7px;
  box-sizing: border-box;
}
.original-text-box {
  /* margin-right: 10px; */
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-right: none;
  border-bottom-left-radius: 7px;
  border-top-left-radius: 7px;
}
.trans-text-box {
  background-color: rgba(75, 75, 75, 0.05);
  border-bottom-right-radius: 7px;
  border-top-right-radius: 7px;
}
.trans-lang-selector,
.original-lang-selector {
  width: 90px;
  height: 30px;
  opacity: 1;
  box-sizing: border-box;
  font-size: 15px;
  line-height: 30px;
  cursor: pointer;
  border: none;
  background-color: transparent;
}
.trans-lang-selector {
  font-size: 15px;
  line-height: 15px;
}
.trans-service-selector {
  border: 1px solid rgba(0, 0, 0, 0.05);
  color: #f16464;
  background-color: rgba(241, 100, 100, 0.05);
  height: 34px;
  width: 80px;
  float: left;
  border-radius: 5px;
  /* margin-right: 10px; */
  text-align: center;
  line-height: 34px;
  cursor: pointer;
  opacity: 1;
}
.trans-google-icon,
.trans-bing-icon {
  font-size: 13px;
  margin: 2px;
  margin-right: 5px;
}
.trans-bing-icon {
  font-size: 14px;
}
.trans-service-selector-inactive {
  border: 1px solid rgba(0, 0, 0, 0.05);
  color: #f16464;
  float: left;
  height: 34px;
  width: 80px;

  border-radius: 5px;
  /* margin-right: 10px; */
  text-align: center;
  line-height: 34px;
  cursor: pointer;
}
.trans-service-selector-container {
  position: absolute;
  top: 20px;
  width: 180px;
  display: flex;
  justify-content: space-between;
}
.trans-button-container {
  position: absolute;
  bottom: 8px;
  right: 20px;
  display: flex;
  justify-content: space-between;
}
.trans-text-box {
  left: 16px;
  top: 16px;
}
.target-lang-container {
  position: absolute;
  width: calc(100% - 32px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  bottom: 16px;
  left: 16px;
}
